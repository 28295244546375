<template>
  <div class="row mx-0 justify-content-center height">
    <div class="col-11 col-sm-10 col-md-9 col-lg-8 col-xl-7 col-xxl-7 h-100 p-0 relative box_shadow">
      <div class="height position-relative overflow-hidden">
        <div v-for="(poster, key) in posters" :key="key" class="position-absolute top-0 bottom-0 start-0 end-0" v-touch:swipe="swipeHandler">
          <transition :name="slideTransition">
            <div class="position-absolute w-100 h-100" v-if="key === activeSlide">
              <Image :imageName="poster.imageUrl" height="418" theClass="d-block w-100 slider_img"  />
              <div class="overlay">
              </div>
              <div class="row mx-0 h-100 align-items-end abs">
                <div class="col-12 p-0 p-4">
                  <div class="row">
                    <div class="col-12 title mb-3">
                      {{poster.name}}
                    </div>
                    <div class="col col-md-9">
                      <Button color="gold" btnText="More Info" icon="arrow" @buttonClicked="goto(poster.project.id)">
                        <IconArrowForward color="black" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="prev" @click="prev">
          <ios-arrow-back />
        </div>
        <div class="next" @click="next"><ios-arrow-forward /></div>
        <div class="pagination position-absolute start-50 translate-middle-x">
          <div class="dot" :class="{active: key === activeSlide}" v-for="(poster, key) in posters" :key="key" @click="goToSlide(key)"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    'ios-arrow-forward': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-forward.vue')),
    'ios-arrow-back': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-back.vue')),

    Image: defineAsyncComponent(() => import('@/components/Image.vue'))
  },
  name: 'AppealSlider',
  props: ['posters'],
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      activeSlide: 0,
      slideTransition: 'slideLeft'
    }
  },
  methods: {
    goto (val) {
      window.scroll(0, 0)
      this.$router.push('/project/' + val)
    },
    goToSlide (val) {
      if (val > this.activeSlide) {
        this.slideTransition = 'slideLeft'
      } else {
        this.slideTransition = 'slideRight'
      }
      this.activeSlide = val
    },
    swipeHandler (direction) {
      if (direction === 'right') {
        this.prev()
      } else if (direction === 'left') {
        this.next()
      }
    },
    next () {
      this.slideTransition = 'slideLeft'
      if (this.activeSlide + 1 === this.posters.length) {
        this.activeSlide = 0
      } else {
        this.activeSlide++
      }
    },
    prev () {
      this.slideTransition = 'slideRight'
      if (this.activeSlide === 0) {
        this.activeSlide = this.posters.length - 1
      } else {
        this.activeSlide--
      }
    }
  }
}
</script>

<style scoped>
.box_shadow {
  box-shadow: 0px 15px 24px 0px rgb(0 0 0 / 21%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 15px 15px 15px 15px;
  padding: 45px 45px 45px 45px;
  border-radius: 15px;
}
.height {
  height: 250px;
}
.slider_img  {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.overlay {
  background-color: transparent;
  background-image: linear-gradient(180deg, #00000000 17%, #000000 100%);
  opacity: 0.81;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
}
.abs {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.title {
  color: #FFFFFF;
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}
.prev, .next {
  background-color: var(--green-color-dark);
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 1;
  font-size: 1rem;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  bottom: 25px;
  top: inherit;
  left: inherit;
  padding: 12px;
  line-height: 1;
}
.prev:hover, .next:hover {
  background-color: var(--green-color);
}
.next {
  right: 24px;
}

.prev{
  right: 70px;
}
.pagination {
  bottom: 7px;
  z-index: 2;
}
.dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  opacity: .8;
  margin: 0 6px;
  cursor: pointer;
}

.dot.active {
  opacity: 1;
  background: var(--green-color);
}
.slideLeft-enter-active, .slideLeft-leave-active {
  transition: all 0.5s ease-out;
}
.slideLeft-enter-from {
  transform: translateX(100%);
}
.slideLeft-leave-to {
  transform: translateX(-100%);
}

.slideRight-enter-active, .slideRight-leave-active {
  transition: all 0.5s ease-out;
}
.slideRight-enter-from {
  transform: translateX(-100%);
}
.slideRight-leave-to {
  transform: translateX(100%);
}
@media (min-width: 992px) {
  .title {
    font-size: 3.1rem;
  }
  .height {
    height: 418px;
  }
  .pagination {
    bottom: 38px;
  }
}
</style>
